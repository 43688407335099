@font-face {
  font-family: 'DIN 2014';
  src: url("/assets/fonts/din/DIN-2014-regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'DIN 2014';
  src: url("/assets/fonts/din/DIN-2014-regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'DIN 2014';
  src: url("/assets/fonts/din/DIN-2014-DEMI.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: 'Monarcha';
  src: url("/assets/fonts/monarcha/Monarcha-SemiBold.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: 'Monarcha';
  src: url("/assets/fonts/monarcha/Monarcha-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: 'Monarcha';
  src: url("/assets/fonts/monarcha/Monarcha-Book.ttf") format("truetype");
  font-weight: 300;
}
