.white-space-no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
