@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

:root {
  --nz-primary-color: @primary-color;
  --nz-light-primary-color: @picker-basic-cell-hover-with-range-color;
  --nz-info-color: @info-color;
  --nz-success-color: @success-color;
  --nz-processing-color: @processing-color;
  --nz-error-color: @error-color;
  --nz-highlight-color: @highlight-color;
  --nz-warning-color: @warning-color;
  --nz-normal-color: @normal-color;
  --color-Chilli: #742620;
  --color-Curry: #B48F3A;
  --color-Herbs: #4B794C;
  --color-Mix: #BB7B32;
  --color-Paprika: #A7372E;
  --color-Pepper: #66686B;
  --color-Salt: #5666A4;
  --color-SingleSpices: #939245;
}

@primary-color: #6e9279;
@background-color-light: #fff;
@table-selection-column-width: 32px;
@skeleton-color: rgba(190, 190, 190, 0.2);
@statistic-unit-font-size: 24px;
@table-row-hover-bg: #f5f5f5;
@image-preview-operation-disabled-color: fade(@image-preview-operation-color, 25%);
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';

@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
0 12px 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-left: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
-12px 0 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-right: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05),
12px 0 48px 16px rgba(0, 0, 0, 0.03);
@shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
0 9px 28px 8px rgba(0, 0, 0, 0.05);
@card-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
0 5px 12px 4px rgba(0, 0, 0, 0.09);

@alert-with-description-padding: @alert-with-description-padding-vertical 15px
@alert-with-description-no-icon-padding-vertical @alert-with-description-icon-size;
